import React, { Component, Fragment } from 'react'; 
import Street from '../../components/BSI4/Street/Street';
import classes from './BSI4.module.css';
import LightBlocks from '../../components/BSI4/LightBlock/LightBlocks';
import LightControls from '../../components/BSI4/LightControls/LightControls';

const ALL_LIGHTS_ON = ["FirstOn", "SecondOn", "ThirdOn", "FourthOn", "FifthOn"];
const FIRST_CASE = ["FirstOn", "SecondInHalfOn", "ThirdOn", "FourthInHalfOn", "FifthOn"];
const SECOND_CASE = ["FirstOn", "SecondInHalfOn", "ThirdInHalfOn", "FourthInHalfOn", "FifthOn"];
const THIRD_CASE = ["FirstOn", "SecondOn", "ThirdInHalfOn", "FourthInHalfOn", "FifthOn"];
const FOURTH_CASE = ["FirstInHalfOn", "SecondOn", "ThirdInHalfOn", "FourthOn", "FifthInHalfOn"];

const MASKS =[ALL_LIGHTS_ON, FIRST_CASE, SECOND_CASE, THIRD_CASE, FOURTH_CASE];

class BSI4 extends Component {
    state = {
        settings: {
            firstSwitch: false,
            secondSwitch: false,
            thirdSwitch: false,
            fourthSwitch: false,
        },
        maskIndex: 0
    }

    lightSwitchHandler = (index) => {
        const updatedSettings = {...this.state.settings};
        // sets all values to false (except index value)
        Object.keys(updatedSettings).forEach((v, i) => i !== index ? updatedSettings[v] = false : updatedSettings[v] = !this.state.settings[v]);
        this.setState({ settings: updatedSettings }, () => this.applyMask(index));
    }

    applyMask(index) {
        if (index === 0 && this.state.settings.firstSwitch) {
            this.setState({ maskIndex: 1 });
        } 
        else if (index === 1 && this.state.settings.secondSwitch) {
            this.setState({ maskIndex: 2 });
        }
        else if (index === 2 && this.state.settings.thirdSwitch) {
            this.setState({ maskIndex: 3 });
        }
        else if (index === 3 && this.state.settings.fourthSwitch) {
            this.setState({ maskIndex: 4 });
        } else {
            this.setState({ maskIndex: 0 });
        }             
    }

    render() { 
        return (
            <Fragment>
                <div className={classes.BSI4}>
                    <Street /> 
                    <LightBlocks lights={MASKS[this.state.maskIndex]}/>
                </div>
                <LightControls onToggle={this.lightSwitchHandler} settings={this.state.settings}/>
            </Fragment>
        );
    }
}
 
export default BSI4;
