import React, { Component, Fragment } from 'react'; 
import Theater from '../../components/BSI3/Theater';
import LightBlock from '../../components/BSI3/LightBlock';
import classes from './Light.module.css';
import LightControls from '../../components/BSI3/LightControls/LightControls';
import Button from '../../components/UI/Button/Button';
import Modal from '../../components/UI/Modal/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { LANG } from '../../constants/constants';

const RED = "rgb(214, 52, 52)";
const BLUE = "rgb(52, 52, 233)";
const YELLOW = "rgb(233, 229, 34)";

class Light extends Component {
    video_1 = React.createRef();
    video_2 = React.createRef();
    state = {
        settings: {
            blue: false,
            red: false,
            white: true,
        },
        leftMaskColor: BLUE,
        rightMakColor: YELLOW,
        isVideo_1_ModalOpen: false,
        isVideo_2_ModalOpen: false
    }

    openModal = (videoNumber) => {
        videoNumber === 1 ? this.setState({ isVideo_1_ModalOpen: true }) : this.setState({ isVideo_2_ModalOpen: true });
    }

    closeModal = (videoNumber) => {
        videoNumber === 1 ? this.setState({ isVideo_1_ModalOpen: false }) : this.setState({ isVideo_2_ModalOpen: false });
        this.stopVideo(videoNumber);
    }

    stopVideo = (videoNumber) => {
        const iframe = videoNumber === 1 ? this.video_1.current : this.video_2.current;
        const iframeSrc = iframe.src;
        iframe.src = iframeSrc;
    }
    
    lightSwitchHandler = (sliderColor) => {
        const updatedSettings = {...this.state.settings};
        let updatedLeftMaskColor = "black";
        let updatedRightMaskColor = "black"
        if (sliderColor === "SliderBlue") {
            if (!this.state.settings.blue && !this.state.settings.red) {
                updatedSettings.blue = !this.state.settings.blue;
                updatedSettings.white = false;
                this.state.settings.blue ? updatedLeftMaskColor = "black" : updatedLeftMaskColor = BLUE;
            } else if (!this.state.settings.blue && this.state.settings.red) {
                updatedSettings.blue = !this.state.settings.blue;
                updatedLeftMaskColor = BLUE;
                updatedRightMaskColor = RED;
            } else if (this.state.settings.blue  && this.state.settings.red) {
                updatedSettings.blue = !this.state.settings.blue;
                updatedRightMaskColor = RED;
            } else if (this.state.settings.blue && !this.state.settings.red) {
                updatedSettings.blue = !this.state.settings.blue;
            }
        }
       
        else if (sliderColor === "SliderRed") {
            if (!this.state.settings.red && !this.state.settings.blue) {
                updatedSettings.red = !this.state.settings.red;
                updatedSettings.white = false;
                this.state.settings.red ? updatedRightMaskColor = "black" : updatedRightMaskColor = RED;
            } else if (!this.state.settings.red && this.state.settings.blue) {
                updatedSettings.red = !this.state.settings.red;
                updatedLeftMaskColor = BLUE;
                updatedRightMaskColor  = RED;
            } else if (this.state.settings.red && this.state.settings.blue) {
                updatedSettings.red = !this.state.settings.red;
                updatedLeftMaskColor = BLUE;
            } else if(this.state.settings.red && !this.state.settings.blue) {
                updatedSettings.red = !this.state.settings.red;
            }   
        }
        
        else if (sliderColor === "SliderWhite") {
            updatedSettings.white = !this.state.settings.white;
            updatedSettings.red = false;
            updatedSettings.blue = false;
            this.state.settings.white ? updatedLeftMaskColor = "black" : updatedLeftMaskColor = BLUE;
            this.state.settings.white ? updatedRightMaskColor = "black" : updatedRightMaskColor = YELLOW;
        } 
        this.setState({settings: updatedSettings, leftMaskColor: updatedLeftMaskColor, rightMakColor: updatedRightMaskColor}); 
    }

    render() { 
        return (
            <Fragment>
                <Modal 
                    title={LANG === "EST" ? "Sissejuhatus" : "Introduction"}
                    show={this.state.isVideo_1_ModalOpen} 
                    modalClosed={() => this.closeModal(1)}>
                    <iframe ref={this.video_1} title="Functions" className={classes.Youtube} src={"https://www.youtube.com/embed/" + (LANG === "EST" ? "0drC-5pVINM" : "ybvmpjLS3Hw")} 
                        frameBorder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; 
                        picture-in-picture" allowFullScreen>
                    </iframe>
                </Modal>
                <Modal 
                    title={LANG === "EST" ? "Kuidas kasutada õppevahendit?" : "How to use artefact?"}
                    show={this.state.isVideo_2_ModalOpen} 
                    modalClosed={() => this.closeModal(2)}>
                    <iframe ref={this.video_2} title="Functions" className={classes.Youtube} src={"https://www.youtube.com/embed/" + (LANG === "EST" ? "qEDrw454oSM" : "ml8Hvxwcjx8")} 
                        frameBorder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; 
                        picture-in-picture" allowFullScreen>
                    </iframe>
                </Modal>
                <div className={classes.Light}>
                    <Theater />
                    {this.state.settings.red ? <LightBlock light="RedOn" /> : <LightBlock light="RedOff" /> }
                    {this.state.settings.blue ? <LightBlock light="BlueOn"/> : <LightBlock light="BlueOff"/> }
                    {this.state.settings.white ? <LightBlock light="BlackOn"/> : <LightBlock light="BlackOff"/> }
                    <LightBlock 
                        light="LeftMask" 
                        style={{background: this.state.leftMaskColor}}/>
                    <LightBlock 
                        light="RightMask"
                        style={{background: this.state.rightMakColor}}/> 
                </div>
                <div className={classes.Controls}>
                    <LightControls onToggle={this.lightSwitchHandler} settings={this.state.settings}/>
                    <div className={classes.VideoButtons}>
                        <FontAwesomeIcon className={classes.Icon} style={{ color: 'rgb(52, 58, 63)' }} icon={faQuestionCircle} size="lg" />
                        <Button btnType="Dark" clicked={() => this.openModal(1)}>Video 1</Button>
                        <Button btnType="Dark" clicked={() => this.openModal(2)}>Video 2</Button>
                    </div> 
                </div>              
            </Fragment>
        );
    }
}
 
export default Light;
