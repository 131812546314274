import React from 'react';
import classes from './CardList.module.css';
import Card from './Card/Card';

const cardList = (props) => (
    <div className={classes.CardList}>
        {props.cardItems.map((cardItem, index) => (
            <Card key={index} cardItem={cardItem} />
        ))}
    </div>
);

export default cardList;
