import React from 'react';
import LightBlock from './LightBlock';

const lightBlocks = (props) => {

    const blocks = props.lights.map((light, index) => (
        <LightBlock 
            key={index}
            light={light} />
    ));

    return (
       <div>
            {blocks}
       </div>
    );
}
 
export default lightBlocks;
