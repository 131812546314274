import React, { Component } from 'react';
import CardList from '../../components/Cards/CardList';
import Footer from '../../components/Footer/Footer';
import { LANG } from '../../constants/constants';

class Home extends Component {
    state = {
        cardsItems: [
            // {name: LANG === 'EST' ? 'Funktsioonid' : 'Functional relations', path: '/SNA_1', image: 'images/sna_1.png'},
            // {name: LANG === 'EST' ? 'Teravnurga trigonomeetria' : 'Trigonometry of acute angle', path: '/SNA_2', image: 'images/sna_2.png'},
            // {name: LANG === 'EST' ? 'Lineaarfunktsioon': 'Linear functions', path: '/SNA_3', image: 'images/sna_3.png'},
            // {name: LANG === 'EST' ? 'Koordinaattasand' : 'Cartesian coordinate system', path: '/SNA_4', image: 'images/sna_4.png'},
            {name: LANG === 'EST' ? 'Newtoni 2. seadus' : 'Newton\'s 2nd law', path: '/BSI_1', image: 'images/bsi_1.png'},
            {name: LANG === 'EST' ? 'Valgus ja värv' : 'Light and colour', path: '/BSI_3', image: 'images/theater.png'},
            {name: LANG === 'EST' ? 'Jada- ja\nrööpühendus' : 'Series and parallel connection', path: '/BSI_4', image: 'images/bsi_4.png'},
        ]
    }
    render() { 
        return ( 
            <div>
                <CardList cardItems={this.state.cardsItems}/> 
                <Footer />            
            </div>
        );
    }
}
 
export default Home;
