import React from 'react';
import classes from './Footer.module.css';

const footer = () => {
    return (
        <footer className={classes.Footer}>
            <div className={classes.Sponsor}>
                <div className={classes.Flag} /> 
                <div className={classes.EdTech} />
                <p className={classes.Description}>This infrastructure is part of a project that has received funding
                    from the European Union’s Horizon 2020 research and innovation 
                    programme under grant agreement No 871275
                </p>
            </div>
            <p className={classes.Copyright}>© 2020-2021, dartef.com</p>
        </footer>
    );
}
 
export default footer;