import React from 'react';
import classes from './Dashboard.module.css';
import Display from '../../../components/UI/Display/Display';
import acceleration from '../../../asserts/icons/BSI1/acceleration.png';
import distance from '../../../asserts/icons/BSI1/distance.png';
import speed from '../../../asserts/icons/BSI1/speed_dashboard.png';
import { faClock } from '@fortawesome/free-solid-svg-icons';
import DisplayFA from '../../UI/Display/DisplayFA';

const dashBoard = (props) => {
    return (
        <div className={classes.Dashboard}>
            <DisplayFA
                style={{width:"100%", margin: "0"}}
                icon={faClock}
                value={props.stopwatch}
                unit="s"/>
            <Display 
                iconType="Reqtangle-m"
                style={{width:"100%", margin: "0"}}
                icon={acceleration}
                value={props.acceleration}
                unit="m/s^2"/>
            <Display 
                iconType="Reqtangle-l"
                style={{width:"100%", margin: "0"}}
                icon={distance}
                value={props.distance}
                unit="m"/>
            <Display 
                iconType="Reqtangle-m"
                style={{width:"100%", margin: "0"}}
                icon={speed}
                value={props.overtakingCarSpeed}
                unit="km/h"/>
        </div>
    );
}
 
export default dashBoard;