import React, { Component } from 'react';
import Layout from './components/Layout/Layout';
import Home from './containers/Home/Home';
import { Route, Switch } from 'react-router-dom';
// import SNA1 from './containers/SNA1/SNA1';
// import SNA2 from './containers/SNA2/SNA2';
// import SNA3 from './containers/SNA3/SNA3';
// import SNA4 from './containers/SNA4/SNA4';
import Light from './containers/BSI3/Light';
import BSI4 from './containers/BSI4/BSI4';
import BSI1 from './containers/BSI1/BSI1';

class App extends Component {
  
  render() { 
    return (
      <div>
        <Layout>
          <Switch>
            <Route path="/" exact component={Home} />
            <Route path="/BSI_1" component={BSI1} />
            <Route path="/BSI_3" component={Light} />
            <Route path="/BSI_4" component={BSI4} />
            {/* <Route path="/SNA_1" component={SNA1} />
            <Route path="/SNA_2" component={SNA2} />
            <Route path="/SNA_3" component={SNA3} />
            <Route path="/SNA_4" component={SNA4} />  */}
          </Switch>
        </Layout>
      </div>
    );
  }
}
 
export default App;
