import React from 'react';
import classes from './ToggleSwitch.module.css';

const toggleSwitch = (props) => (
    <label className={classes.Switch}>
        <input 
            type="checkbox" 
            checked={props.isToggled} 
            onChange={props.onToggle} />
        <span className={[classes[props.sliderColor], classes[props.sliderType]].join(' ')} data-content={props.content}/>
        <p>{props.text}</p>
    </label>
);

export default toggleSwitch;
