import React, { Component } from 'react';
import { Fragment } from 'react';
import Grass from '../../components/BSI1/Grass/Grass';
import Road from '../../components/BSI1/Road/Road';
import RoadCover from '../../components/BSI1/Road/RoadCover';
import classes from './BSI1.module.css';
import Vehicle from '../../components/Vehicle/Vehicle';
import car from '../../asserts/vehicles/car.png';
import Controls from '../../components/BSI1/Controls/Controls';
import DashBoard from '../../components/BSI1/Dashboard/Dashboard';

const SPEED_PROPORTION = 0.0289;        // 0.0289 -> 1 km/h
const ROAD_LENGTH = 1350;               // Pixels
const ONCOMING_CAR_SPEED = 2.602;       // 0.0289 -> 1 km/h (2.602 = 90km/h)
const ONCOMING_CAR_Y = 83;
const IN_FRONT_CAR_X = 140;
const IN_FRONT_CAR_Y = 123;
const OVERTAKE_RELATIVE_DISTANCE = 24;  // meters

class BSI1 extends Component {
    oncomingCar = React.createRef();
    overtakingCar = React.createRef();
    state = {
        roadSectionCoordinates: [0, 50, 100, 150, 200, 250, 300, 350, 400, 450, 500, 550, 600, 650, 
            700, 750, 800, 850, 900, 950, 1000, 1050, 1100, 1150, 1200, 1250, 1300],
        settings: {                     //Form Data
            overtakingCarSpeed: 0,    
            overtakingCarTraction: 0,
            overtakingCarMass: 0,
            overtakingCarFriction: 0,
            wind: 0,
        },
        overtakingCar_x: 65,
        overtakingCar_y: 123,
        oncomingCar_x: 65,
        inFrontCarSpeed: 0,
        acceleration: 0,
        finalSpeed: 0,
        speedChange: 0,
        distanceToOncomingCar: 236,
        canStart: false,
        isDataReseted: true,
        inputDisabled: false,
        time: 0,
        isTimerOn: false,
        start: 0,
        windowWidth: window.innerWidth,
    }

    componentDidMount() {
        window.addEventListener("resize", this.handleResize); 
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleResize); 
    }

    handleResize = (event) => {
        this.setState({ windowWidth: window.innerWidth });
    };

    getFormData = (formData) => {
        this.setState({ 
            settings: formData, 
            inFrontCarSpeed: formData.overtakingCarSpeed, 
            canStart: true,
            isDataReseted: false,
            inputDisabled: true,
        }, () => this.setAccelerationAndFinalSpeed());
    }

    setAccelerationAndFinalSpeed() {
        const acceleration = (this.state.settings.overtakingCarTraction - this.state.settings.overtakingCarFriction + this.state.settings.wind) /
            this.state.settings.overtakingCarMass;
        const overtakeTime = Number(Math.sqrt(48 / acceleration));
        const finalSpeed = Math.round(3.6 * (this.state.settings.overtakingCarSpeed / 3.6 + acceleration * overtakeTime));
        this.setState({ acceleration, finalSpeed })
    }

    handleStartClick = () => {
        this.state.isTimerOn ? this.stopTimer() : this.startTimer(); 
    }

    handleResetClick = () => {
        clearInterval(this.timer);
        this.setState({ 
            time: 0,
            isTimerOn: false,
            start: 0,
            oncomingCar_x: 65,
            overtakingCar_x: 65,
            overtakingCar_y: 123,
            inFrontCarSpeed: 0,
            acceleration: 0,
            finalSpeed: 0,
            speedChange: 0,
            distanceToOncomingCar: 236,
            canStart: false,
            isDataReseted: true,
            inputDisabled: false,
            settings: {                     
                overtakingCarSpeed: 0,    
                overtakingCarTraction: 0,
                overtakingCarMass: 0,
                overtakingCarFriction: 0,
                wind: 0,
            }
         });
    }

    startTimer = () => {
        this.setState({
            isTimerOn: true,
            time: this.state.time,
            start: Date.now() - this.state.time,
        });
        this.timer = setInterval(() => {
            this.setState({ time: Date.now() - this.state.start });
            this.checkBounds();
            this.collisionCheck();
            this.moveRoad();
            this.moveOncomingCar();
            this.moveOvertakingCar();
        }, 20);
    }

    stopTimer = () => {
        this.setState({ isTimerOn: false });
        clearInterval(this.timer);
    }

    moveRoad() {
        const tempCoordinates = [...this.state.roadSectionCoordinates];
        const updatedCoordinates = tempCoordinates.map(y => {
            return (y + this.state.inFrontCarSpeed * SPEED_PROPORTION) % ROAD_LENGTH
      });
      this.setState({roadSectionCoordinates: updatedCoordinates});
    }

    moveOncomingCar() {
        const updatedOncomingCar_x = ONCOMING_CAR_SPEED + this.state.inFrontCarSpeed * SPEED_PROPORTION + this.state.oncomingCar_x;
        const distance = Math.round((1231 + 120 - this.state.overtakingCar_x - this.state.oncomingCar_x) / 5.2);
        this.setState({ oncomingCar_x: updatedOncomingCar_x, distanceToOncomingCar: distance });
    }

    moveOvertakingCar() {
        const updatedOvertakingCar_x = this.state.overtakingCar_x + this.state.speedChange;
        let speedChange = this.state.speedChange;
        if ((this.state.overtakingCar_x - 65) / 5.2 < OVERTAKE_RELATIVE_DISTANCE) {
            speedChange = this.state.speedChange + this.state.acceleration / 50 * 3.6 * SPEED_PROPORTION;
        }
        
        if (IN_FRONT_CAR_X - this.state.overtakingCar_x < 70 && IN_FRONT_CAR_X - this.state.overtakingCar_x > 0 && this.state.overtakingCar_y > ONCOMING_CAR_Y) {
            const updatedOvertakingCar_y = this.state.overtakingCar_y - this.state.speedChange * 2;
            this.setState({ overtakingCar_y: updatedOvertakingCar_y });
        }
        else if (IN_FRONT_CAR_X - this.state.overtakingCar_x < -40 && this.state.overtakingCar_y < IN_FRONT_CAR_Y) {
            const updatedOvertakingCar_y = this.state.overtakingCar_y + this.state.speedChange * 1.5;
            this.setState({ overtakingCar_y: updatedOvertakingCar_y });
        }
        else if (this.state.overtakingCar_y > IN_FRONT_CAR_Y) {
            this.setState({ overtakingCar_y: IN_FRONT_CAR_Y });
        }
        else if (this.state.overtakingCar_y < ONCOMING_CAR_Y) {
            this.setState({ overtakingCar_y: ONCOMING_CAR_Y });
        }
        this.setState({ overtakingCar_x: updatedOvertakingCar_x, speedChange });
        
    }

    checkBounds() {
        if (this.state.overtakingCar_x > ROAD_LENGTH || this.state.oncomingCar_x >= ROAD_LENGTH + 5 || 
            (this.state.overtakingCar_y >= IN_FRONT_CAR_Y && IN_FRONT_CAR_X - this.state.overtakingCar_x < -40)) {
            this.setState({ isTimerOn: false, canStart: false });
            clearInterval(this.timer);
        }
    }

    collisionCheck() {
        if (this.overtakingCar.current.getBoundingClientRect().right > this.oncomingCar.current.getBoundingClientRect().left &&
        this.overtakingCar.current.getBoundingClientRect().top < this.oncomingCar.current.getBoundingClientRect().bottom) {
            this.setState({ isTimerOn: false, canStart: false });
            clearInterval(this.timer);
        }
    }

    render() { 
        return (
            this.state.windowWidth > 415 ?
            <Fragment>
                <div className={classes.RoadContainer}>
                    <RoadCover />
                    <Grass />
                    <RoadCover />
                    <Road roadSectionCoordinates={this.state.roadSectionCoordinates}/>
                    <Vehicle 
                        ref={this.overtakingCar}
                        position={{
                            left: this.state.overtakingCar_x,
                            top: this.state.overtakingCar_y,
                            width: '13px',
                            height: '26px',
                            borderRadius: '3px',
                            transform: 'rotate(90deg)',
                            backgroundColor: 'rgb(240, 49, 49)',
                            backgroundImage: `url(${car})`
                        }}/>
                    <Vehicle 
                        position={{
                            left: IN_FRONT_CAR_X,
                            top: IN_FRONT_CAR_Y,
                            width: '13px',
                            height: '26px',
                            borderRadius: '3px',
                            transform: 'rotate(90deg)',
                            backgroundColor: 'rgb(12, 134, 216)',
                            backgroundImage: `url(${car})`
                        }}/>
                    <Vehicle 
                        ref={this.oncomingCar}
                        position={{
                            right: this.state.oncomingCar_x,
                            top: ONCOMING_CAR_Y,
                            width: '13px',
                            height: '26px',
                            borderRadius: '3px',
                            transform: 'rotate(-90deg)',
                            backgroundColor: 'rgb(255, 239, 55)',
                            backgroundImage: `url(${car})`
                        }}/>
                </div>
                <div className={classes.ControlsContainer}>
                    <Controls 
                        formDataCallback={this.getFormData} 
                        start={this.handleStartClick}
                        reset={this.handleResetClick}
                        canStart={this.state.canStart}
                        isDataReseted={this.state.isDataReseted}
                        inputDisabled={this.state.inputDisabled}
                        isTimerOn={this.state.isTimerOn} />
                    <DashBoard 
                        stopwatch={(this.state.time / 1000).toFixed(2)}
                        acceleration={this.state.acceleration.toFixed(2)} 
                        distance={this.state.distanceToOncomingCar}
                        overtakingCarSpeed={(this.state.settings.overtakingCarSpeed + this.state.speedChange / 0.0289).toFixed(0)}/>
                </div>  
            </Fragment> :
            <div className={classes.Desktop}>
                <div className={classes.Img} /> 
            </div> 
        );
    }
}
 
export default BSI1;
