import React from 'react';
import classes from './LightControls.module.css';
import ToggleSwitch from '../../UI/ToggleSwitch/ToggleSwitch';

const controls = [
    {sliderColor: "SliderBlue", sliderType: "Rounded" },
    {sliderColor: "SliderRed", sliderType: "Rounded" },
    {sliderColor: "SliderWhite", sliderType: "Rounded" },
];

const lightControls = (props) => {
    const settingsValues = Object.values(props.settings);

    const switches = controls.map((control, index) => (
        <ToggleSwitch 
            onToggle={() => props.onToggle(control.sliderColor)}
            isToggled={settingsValues[index]}
            key={control.sliderColor}
            sliderColor={control.sliderColor}
            sliderType={control.sliderType}
        />
    ));
    
    return (
        <div className={classes.LightControls}>
            {switches}
        </div>
    );
}

export default lightControls;
