import React from 'react';
import classes from './LightBlock.module.css';

const lightBlock = (props) => (
    <div 
        className={[classes.LightBlock, classes[props.light]].join(' ')}
        style={props.style} >       
    </div>
);

export default lightBlock;
