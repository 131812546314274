import React from 'react';
import classes from './Card.module.css';
import { Link } from 'react-router-dom';
//import geometry from '../../../asserts/cards/geometry.jpg';

const card = (props) => (
    <Link className={classes.CardLink} to={props.cardItem.path}>
        <img className={classes.Img} alt="Pic" src={props.cardItem.image}/>
        <div className={classes.CardInfo}>
            <h2>{props.cardItem.name}</h2>
        </div>
    </Link>
);

export default card;
