import React from 'react';
import Form from '../../../containers/BSI1/Form/Form';
import AnimatedButton from '../../UI/Button/AnimatedButton';
import Button from '../../UI/Button/Button';
import classes from './Controls.module.css';

const controls = (props) => {
    return (
        <div className={classes.Controls}>
            <Form 
                canStart={props.canStart} 
                isDataReseted={props.isDataReseted}
                inputDisabled={props.inputDisabled}
                formDataCallback={formData => props.formDataCallback(formData)}/>
            <div style={{display: "flex"}}>
                <AnimatedButton 
                    btnType="Danger"
                    style={{width: "calc(50% - 20px)"}}
                    disabled={!props.canStart}
                    clicked={() => props.start()}>
                    {!props.isTimerOn ? 'START' : 'PAUSE'}
                </AnimatedButton>
                <Button 
                    btnType="Primary"
                    style={{width: "calc(50% - 20px)"}}
                    clicked={() => props.reset()}>
                    RESET
                </Button>
            </div>
        </div>
    );
}
 
export default controls;
