import React from 'react';
import classes from './RoadCover.module.css'

const roadCover = () => {
  
    return <div>
        <div className={classes.RoadCover} />  
    </div> 
};

export default roadCover;