import React, { Component } from 'react';
import Input from '../../../components/UI/Input/Input';
import Button from '../../../components/UI/Button/Button';
import speed from '../../../asserts/icons/BSI1/speed.png';
import engine from '../../../asserts/icons/BSI1/engine.png';
import mass from '../../../asserts/icons/BSI1/mass.png';
import friction from '../../../asserts/icons/BSI1/friction.png';
import wind from '../../../asserts/icons/BSI1/wind.png';
import mootor from '../../../asserts/icons/BSI1/mootor.png';
import tuul from '../../../asserts/icons/BSI1/tuul.png';
import hoord from '../../../asserts/icons/BSI1/hoord.png';
import { LANG }  from '../../../constants/constants';


class Form extends Component {
    state = {
        form: {
            overtakingCarSpeed: {
                elementType: 'input',
                elementConfig: {
                    type: 'number',
                    placeholder: LANG === 'EST' ? 'Sõiduki algkiirus, km/h' : 'Initial velocity, km/h',
                    icon: speed,
                    icontype: 'Reqtangle-l'
                },
                value: '',
                validation: {
                    required: true,
                    minSpeed: 0,
                    maxSpeed: 120,
                    errorMessage: 'Min: 0, Max: 120'
                },
                valid: false,
                touched: false,
            },
            overtakingCarTraction: {
                elementType: 'input',
                elementConfig: {
                    type: 'number',
                    placeholder: LANG === 'EST' ? 'Mootori veojõud, N' : 'Engine traction, N',
                    icon: LANG === 'EST' ? mootor : engine,
                    icontype: 'Reqtangle-l'
                },
                value: '',
                validation: {
                    required: true,
                    minTraction: 5000,
                    maxTraction: 15000,
                    errorMessage: 'Min: 5000, Max: 15000'
                },
                valid: false,
                touched: false,
            },
            overtakingCarMass: {
                elementType: 'input',
                elementConfig: {
                    type: 'number',
                    placeholder: LANG === 'EST' ? 'Sõiduki mass, kg' : 'Vehicle mass, kg',
                    icon: mass,
                    icontype: 'Reqtangle-l'
                },
                value: '',
                validation: {
                    required: true,
                    minMass: 500,
                    maxMass: 2500,
                    errorMessage: 'Min: 500 Max: 2500'
                },
                valid: false,
                touched: false,
            },
            overtakingCarFriction: {
                elementType: 'input',
                elementConfig: {
                    type: 'number',
                    placeholder: LANG === 'EST' ? 'Hõõrdejõud, N' : 'Friction force, N',
                    icon: LANG === 'EST' ? hoord : friction,
                    icontype: 'Reqtangle-l'
                },
                value: '',
                validation: {
                    required: true,
                    minFriction: 500,
                    maxFriction: 1500,
                    errorMessage: 'Min: 500 Max: 1500'
                },
                valid: false,
                touched: false,
            },
            wind: {
                elementType: 'input',
                elementConfig: {
                    type: 'number',
                    placeholder: LANG === 'EST' ? 'Tuule rõhujõud, N' : 'Wind force, N',
                    icon: LANG === 'EST' ? tuul : wind,
                    icontype: 'Reqtangle-l'
                },
                value: '',
                validation: {
                    required: true,
                    minWind: -500,
                    maxWind: +500,
                    errorMessage: 'Min: -500, Max: 500'
                },
                valid: false,
                touched: false,
            },
        },
        formIsValid: false,
    }

    checkValidity(value, rules) {
        let isValid = true;
        if (rules.required) {
            isValid = value.trim() !== '' && isValid;
        }
        if (rules.maxSpeed) {
            isValid = (value >= rules.minSpeed && value <= rules.maxSpeed) && isValid;
        }
        if (rules.minTraction) {
            isValid = (value >= rules.minTraction && value <= rules.maxTraction) && isValid;
        }
        if (rules.minMass) {
            isValid = (value >= rules.minMass && value <= rules.maxMass) && isValid;
        }
        if (rules.minFriction) {
            isValid = (value >= rules.minFriction && value <= rules.maxFriction) && isValid;
        }
        if (rules.minWind) {
            isValid = (value >= rules.minWind && value <= rules.maxWind) && isValid;
        }
        return isValid;
    }

    inputChangedHandler = (event, id) => {
        const updatedForm = {...this.state.form};
        const updatedItem = {...updatedForm[id]}; 
        updatedItem.value = event.target.value;  
        updatedItem.valid = this.checkValidity(updatedItem.value, updatedItem.validation); 
        updatedItem.touched = true;
        updatedForm[id] = updatedItem;

        let formIsValid = true;
        for(let id in updatedForm) {
            formIsValid = updatedForm[id].valid && formIsValid;
        }
        this.setState({ form: updatedForm, formIsValid });
    }

    dataHandler = (event) => {
        event.preventDefault();
        const formData = {};
        for (let key in this.state.form) {
            formData[key] = Number(this.state.form[key].value);
        }
        this.props.formDataCallback(formData);
    }

    render() { 
        const formElementsArray = [];
        for(let key in this.state.form) {
            formElementsArray.push({
                id: key,
                config: this.state.form[key]
            });
        }
        return (
            <form onSubmit={this.dataHandler}> 
                {formElementsArray.map((formElement, index) =>(
                    <div key={formElement.id} style={{display: "flex", alignItems:"center"}}>
                        <Input 
                            elementType={formElement.config.elementType}
                            elementConfig={formElement.config.elementConfig}
                            value={formElement.config.value}
                            invalid={!formElement.config.valid}
                            touched={formElement.config.touched}
                            validation={formElement.config.validation}
                            disabled={this.props.inputDisabled}
                            changed={(event) => this.inputChangedHandler(event, formElement.id)}/> 
                        {index === 0 ? <p style={{ overflow:"hidden",width: "100px", border: "1px solid #ccc", padding:"6px 6px", margin:"10px"}}>{(formElement.config.value / 3.6).toFixed(2) + "m/s"}</p> : null} 
                    </div>
                ))}
                    <Button 
                        btnType="Success" 
                        disabled={!(this.state.formIsValid && !this.props.canStart && this.props.isDataReseted)}
                        style={{width: "calc(100% - 20px)", margin: "10px"}}>OK
                    </Button>
                </form>
                 
            
        );
    }
}
 
export default Form;
